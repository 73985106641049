<template>
  <div class="order-list">
    <!-- 查詢 -->
    <el-row :gutter="10" style="margin-bottom: 20px;">
      <el-col :span="5"><el-input v-model="tableParams.kw" placeholder="输入用户手机号/卡券名称/卡券id搜索" @input="kwChange" clearable
          class="" /></el-col>
      <el-col :span="4"> <el-select v-model="tableParams.paymentType" placeholder="请选择卡券获取方式" @change="reload" clearable
          class="">
          <el-option v-for="item in ObjToOpt(getTypeObj)" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select></el-col>
      <el-col :span="4"> <el-select v-model="tableParams.cardStatus" placeholder="请选择卡券订单状态" @change="reload" clearable
          class="">
          <el-option v-for="item in ObjToOpt(cardOrderStatusObj)" :key="item.value" :label="item.label"
            :value="item.value">
          </el-option>
        </el-select></el-col>
      <el-col :span="4"> <el-select v-model="tableParams.cardType" placeholder="请选择卡券类型" @change="reload" clearable
          class="">
          <el-option v-for="item in ObjToOpt(cardTypeObj)" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select></el-col>
      <el-col :span="5"> <el-date-picker v-model="dateToDate" type="daterange" align="center" unlink-panels
          range-separator="~" start-placeholder="到期时间" end-placeholder="到期时间" :picker-options="pickerOptions"
          @change="reload" clearable value-format="yyyy-MM-dd" style="width: 250px;">
        </el-date-picker></el-col>
      <el-col :span="2"><el-button type="primary" @click="onExport" style="margin-left: 20px;">导出</el-button></el-col>
    </el-row>
    <!-- 表格 -->
    <el-table :data="tableData" border stripe :header-cell-style="{
      background: '#FAFAFA',
      color: '#000000',
    }">
      <el-table-column label="获得时间" align="center" width="100">
        <template slot-scope="scope">
          <span>{{ scope.row.addDt || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户信息" align="center" min-width="180">
        <template slot-scope="scope">
          <span>{{ scope.row.userName || "-" }}</span>
          <span>&nbsp;|&nbsp;</span>
          <span>{{ scope.row.mobile || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券名称" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.cardName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="适用范围" align="center" min-width="280" prop="roomLimit">
      </el-table-column>
      <el-table-column label="可用日期" min-width="150">
        <template slot-scope="scope">
          <p v-for="(item, index) in scope.row.cardSuit" :key="index">
            <span>*该卡券{{ item.suitWriting }}，</span>
            <span v-if="item.suitStartTime == '00:00:00' &&
      item.suitEndTime == '00:00:00'
      ">全天可用</span>
            <span v-else>{{ item.suitStartTime }}-{{ item.suitEndTime }}可用</span>
            <span v-if="item.continuousMaxHour &&
      item.continuousMaxHour != 0 &&
      item.continuousMaxHour != 24
      ">{{ item.continuousMaxHour }}小时</span>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="获得方式" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentTypeName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="卡券id" prop="memberCardId" align="center"></el-table-column>
      <el-table-column label="卡券剩余" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.residue || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="有效期至" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.validDate || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span :style="{ color: cardOrderStatusColor[scope.row.cardStatus] }">{{ scope.row.cardStatusName || "-"
            }}</span>
        </template>
      </el-table-column>

    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination layout="total,sizes, prev, pager, next,jumper" background :total="total"
        :page-size="tableParams.size" @size-change="onSizeChange" :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage" @current-change="onCurrentPage">
      </el-pagination>
    </section>
  </div>
</template>

<script>
import {
  getTableList,
  getStopStartCard,
  getCardOrderExport,
} from "@/api/card/order";
import { getServiceList } from "@/api/resource/room";
import { ObjToOpt } from "@/utils/utils";
import { merchantStatusObj } from "@/db/objs";

import {
  pickerOptions,
  getTypeObj,
  cardOrderStatusObj,
  cardOrderStatusColor,
  cardTypeObj,
  cardTipsObj,
} from "@/db/objs";
export default {

  data() {
    return {
      ObjToOpt,
      merchantStatusObj,
      getTypeObj,
      cardOrderStatusObj,
      cardOrderStatusColor,
      cardTypeObj,
      cardTipsObj,
      pickerOptions,
      serviceList: [],
      dateToDate: [],
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [], //表格数据
      tableParams: {
        page: 1,
        size: 10,
        merchantId:0, //商户ID
        shopId: "", //店铺ID
        kw: "", //查询关键字
        serviceId: "", //店铺ID
        dateFrom: "", //店铺ID
        dateTo: "", //店铺ID
        paymentType: "", //店铺ID
        cardStatus: "", //店铺ID
        cardType: "", //店铺ID
        memberId: this.$route.query.memberId,
      },
    };
  },
  created() {
    // let merchantShopId = window.localStorage.getItem(
    //   "playOne-store-merchantShopId"
    // );
    // if (merchantShopId) {
    //   this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
    //   this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    // }
  },
  mounted() {
    this.getTableList(); // 【请求】表格数据
    this.getServiceList(); // 【请求】表格数据
  },
  methods: {
    // 【请求】表格数据
    getTableList() {
      if (this.dateToDate) {
        this.tableParams.dateFrom = this.dateToDate[0];
        this.tableParams.dateTo = this.dateToDate[1];
      } else {
        this.tableParams.dateFrom = "";
        this.tableParams.dateTo = "";
      }
      let data = this.tableParams;
      getTableList(data).then((res) => {
        if (res.isSuccess == "yes") {
          if(res.data){
            this.tableData = res.data.list;
            this.total = res.data.total;
          }else{
            this.tableData =[];
            this.total = 0;
          }
        }
      });
    },

    // 【请求】服务列表
    getServiceList() {
      let data = {
        shopId: this.tableParams.shopId,
      };
      getServiceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.serviceList = res.data;
        }
      });
    },



    // 【请求】禁用或启用卡券
    getStopStartCard(data) {
      getStopStartCard(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getTableList();
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getTableList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getTableList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },





    // 【监听】导出
    onExport() {
     
      getCardOrderExport(this.tableParams);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-list {
  padding: 0.15rem;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}
</style>